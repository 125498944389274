<template>
  <div class="courses flex-column">
    <v-img width="100%" height="550px" :src="career.cover">
      <div class="image-overlay d-flex flex-column justify-center px-13">
        <div>
          <span class="text-h2 font-weight-medium white--text">
            {{career.name}}
          </span>
        </div>
        <!-- <div class="pt-10">
          <span class="text-subtitle-1 font-weight-medium white--text">
            DEL {{formatedDateStart}} DE {{formatedDateStartMonth.toUpperCase()}} AL {{formatedDateEnd}} DE {{formatedDateEndMonth.toUpperCase()}}
          </span>
        </div>
        <div class="pt-3">
          <span class="text-subtitle-1 font-weight-medium white--text">
            HORA: {{period.timeStart}} A {{period.timeEnd}} hs.
          </span>
        </div> -->
        <div class="pt-3">
          <span class="text-subtitle-1 white--text">
            {{career.modalities.map((modality) => modality.charAt(0).toUpperCase() + modality.slice(1)).join(' y ')}}
          </span>
        </div>
        <div class="pt-3">
          <span class="text-subtitle-1 white--text">
            PROGRAMAS LIBRES Y GRATUITOS
          </span>
        </div>
        <div class="pt-6">
          <v-btn class="comntained" color="secondary">
            INSCRIBITE AHORA
          </v-btn>
        </div>
      </div>
    </v-img>
    <div class="content-container d-flex justify-center py-13">
      <div class="inner-content-container">
        <div class="pb-2">
          <span class="text-h4 font-weight-bold secondary--text">
            MODALIDAD
          </span>
        </div>
        <div>
          <span class="text-subtitle-1 font-weight-bold">
            {{career.modalities.map((modality) => modality.charAt(0).toUpperCase() + modality.slice(1)).join(' y ')}}
          </span>
        </div>
        <div class="pt-5">
          <span class="text-h4 font-weight-bold secondary--text">
            DISERTANTES
          </span>
        </div>
        <div>
          <span class="text-subtitle-1 font-weight-bold">
            Adrian Loys
          </span>
        </div>
        <div>
          <span class="text-subtitle-1 font-weight-bold">
            Juan Maestri
          </span>
        </div>
        <div>
          <span class="text-subtitle-1 font-weight-bold">
            Lucas Dalla Costa
          </span>
        </div>
        <div class="pt-5">
          <span class="text-h4 font-weight-bold secondary--text">
            PROGRAMA
          </span>
        </div>
        <div class="py-5" :key="`day-${index}`" v-for="(day, index) in days">
          <ProgramDay
            :number="index+1"
            :dayTitle="day.name"
            :items="day.items"
          />
        </div>
      </div>
    </div>
    <div class="full-width px-13 py-7 grey lighten-2">
      <div class="pb-3">
        <span class="text-h4 font-weight-bold secondary--text">
          IMPORTANTE:
        </span>
      </div>
      <div class="px-5">
        <div>
          <span class="text-subtitle-1 font-weight-medium">
            Incluye clases de nivelacion pre-curso
          </span>
        </div>
        <div>
          <span class="text-subtitle-1 font-weight-medium">
            Contar con conexion a internet de alta velocidad
          </span>
        </div>
        <div>
          <span class="text-subtitle-1 font-weight-medium">
            Requisitos minimos del equipo: Procesador intel i5 de 9na Gen.
          </span>
        </div>
        <div>
          <span class="text-subtitle-1 font-weight-medium">
            Placa de video dedicada
          </span>
        </div>
        <div>
          <span class="text-subtitle-1 font-weight-medium">
            8gb de ram
          </span>
        </div>
      </div>
    </div>
    <div class="full-width px-13 py-7 secondary d-flex">
      <div>
        <span class="text-h4 font-weight-bold white--text">
          +INFO
        </span>
      </div>
      <div class="d-flex align-center px-10">
        <v-icon class="pa-2" color="white">
          mdi-whatsapp
        </v-icon>
        <span class="text-h5 font-weight-bold white--text">
          <a href="https://wa.me/+543516328580/?text=Hola%21%20Quiero%20obtener%20informacion%20sobre%20los%20cursos%21">+54 351 6328580</a>
        </span>
      </div>
      <div class="d-flex align-center px-10">
        <v-icon class="pa-2" color="white">
          mdi-email
        </v-icon>
        <span class="text-h5 font-weight-bold white--text">
          info@tod3doficial.com.ar
        </span>
      </div>
    </div>
    <div class="sponsors-container d-flex justify-space-around align-center py-4">
      <v-img max-width="100px" src="../../assets/images/sponsor1.jpg"/>
      <v-img max-width="100px" src="../../assets/images/sponsor2.jpg"/>
      <v-img max-width="100px" src="../../assets/images/sponsor3.jpg"/>
      <v-img max-width="100px" src="../../assets/images/sponsor4.jpg"/>
      <v-img max-width="100px" src="../../assets/images/sponsor5.jpg"/>
    </div>
  </div>
</template>

<script>
import { db, storage } from '@/firebaseSDK'
import ProgramDay from '@/components/ProgramDay'
import moment from 'moment'

export default {
  name: 'Courses',

  components: {
    ProgramDay
  },

  data: () => ({
    career: {},
    period: {},
    images: [],
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
    days: [
      {
        name: 'MESHMIXER',
        items: [
          {
            name: 'Analisis de los modelos'
          },
          {
            name: 'Preparacion de los modelos para impresion 3D'
          },
          {
            name: 'Copia Biogenetica'
          },
          {
            name: 'Montaje de articulador virtual'
          },
          {
            name: 'Encerado facialmente guiado'
          },
          {
            name: 'mockup indirecto SHELLS'
          },
          {
            name: 'provisorios y perfil de emergencia digital'
          },
          {
            name: 'Texturizacion digital de provisorios'
          }
        ]
      },
      {
        name: 'BLUESKYBIO',
        items: [
          {
            name: 'Introduccion a Blueskybio'
          },
          {
            name: 'Herramientas del software'
          },
          {
            name: 'combinado DICOM - STL'
          },
          {
            name: 'Planificacion quirurgica'
          },
          {
            name: 'Ventajas y desventajas de cajas Full Guide'
          },
          {
            name: 'Guia para cajas convecionales protocolo TAC'
          }
        ]
      },
      {
        name: 'IMPRESIONES 3D',
        items: [
          {
            name: 'Que es una impresora 3D?'
          },
          {
            name: 'Partes y funciones de una impresora 3D'
          },
          {
            name: 'Calibracion'
          },
          {
            name: 'Primeros pasos'
          }
        ]
      },
      {
        name: 'CIRUGIA A CARGO DE CURSANTES'
      }
    ],
    focus: ''
  }),

  computed: {
    formatedDateStart () {
      return moment.unix(this.period.dateStart).format('DD')
    },

    formatedDateStartMonth () {
      return moment.unix(this.period.dateStart).format('MMMM')
    },

    formatedDateEnd () {
      return moment.unix(this.period.dateEnd).format('DD')
    },

    formatedDateEndMonth () {
      return moment.unix(this.period.dateStart).format('MMMM')
    },
  },

  methods: {
    showEvent ({ nativeEvent, event }) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => this.selectedOpen = true))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },

    async formatCareer (career) {
      let coverImage = await storage.refFromURL(career.cover).getDownloadURL()
      console.log(coverImage)
      return {
        ...career,
        cover: coverImage
      }
    }
  },
  
  async mounted () {
    try {
      let career = await db.collection("careers").where("name", "==", this.$router.currentRoute.params.id).get()
      const careerData = await this.formatCareer(career.docs[0].data())
      this.career = careerData
      let period = await db.collection("periods").where("courseId", "==", career.docs[0].id).get()
      let fetchedImages = career.docs[0].data().images.map((image) => storage.refFromURL(image).getDownloadURL())
      let imagesRetrieved = await Promise.allSettled(fetchedImages)
      this.images = imagesRetrieved.map(image => image.value)
      console.log(period)
      this.period = period.docs[0].data()
    } catch (err) {
      return err
    }
  }
}
</script>

<style lang="scss" scoped>
.courses {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.timeline-container {
  width: 600px;
}

.images-container {
  max-width: 450px;
  width: 100%;
  flex: 0 1;
}

.timeline {
  max-width: '500px';
  position: relative;
  left: -20px;

  @media (min-width: 960px) {
    left: 0px;
  }
}

.get-more-info {
  position: relative;
  left: 30px;

  @media (min-width: 960px) {
    left: 50px;
  }
}

.price-link {
  color: rgb(2, 3, 2);
  text-decoration: none;
}

.full-width {
  width: 100%;
}

.blurred {
  color: transparent;
  text-shadow: 0 0 5px rgba(0,0,0,0.5);
}

.max-width{
  max-width: 100%;
  height: 300px;
}

.image-overlay {
  width: 40%;
  height: 100%;
  box-sizing: border-box;
}

.content-container {
  width: 100%;
}

.inner-content-container {
  width: 80%;
}

.sponsors-container {
  width: 100%;
  background-color: #d9dade;
}
</style>
