<template>
  <div>
    <div>
      <span class="text-subtitle-1 font-weight-bold">
        DIA {{number}}
      </span>
    </div>
    <div>
      <span class="text-subtitle-1 font-weight-bold">
        {{dayTitle}}
      </span>
    </div>
    <div>
      <div :key="item.name" v-for="item in items">
        <v-icon color="secondary">
          mdi-circle-small
        </v-icon>
        <span class="text-subtitle-1 font-weight-medium">
          {{item.name}}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
// import { storage } from '@/firebaseSDK'

export default {
  name: 'ProgramDay',

  props: {
    number: {
      type: Number
    },
    dayTitle: {
      type: String
    },
    items: {
      type: Array
    }
  },

  data: () => {
    return {
      
    }
  },

  methods: {
  },

  async mounted () {
  }
}
</script>

<style lang="scss" scoped>

</style>